.full-width-control {
  width: 100%;

  > div {
    width: 100%;
  }
}

.classification-questionnaire-score-binding {
  display: grid;
  grid-template-columns: 10rem 1fr 7rem;
  grid-template-rows: 4rem 4rem 4rem;
  row-gap: 1rem;

  .label {
    font-size: 1.5rem;
    line-height: 4rem;

    &.low {
      background-color: $light-green;
    }

    &.moderate {
      background-color: $pending;
    }

    &.high {
      background-color: $tomato-red;
    }
  }

  .score {
    font-size: 1.5rem;
    line-height: 4rem;
  }
}

.classification-questionnaire-preview {
  .sublabel {
    color: $warm-gray;
  }

  .score {
    color: $curious-blue;
  }
}

.classification-questionnaire-score-binding-preview {
  > div {
    width: 6rem;
  }

  .high {
    > div:first-of-type {
      color: $tomato-red;
    }

    .bar {
      border-right-width: 1px;
    }

    > div:last-of-type {
      border-right: 1px solid $black;
    }
  }

  .moderate {
    > div:first-of-type {
      color: $pending;
    }

    .bar {
      border-left-width: 1px;
      border-right-width: 1px;
    }
  }

  .low {
    > div:first-of-type {
      color: $light-green;
    }

    .bar {
      border-left-width: 1px;
    }

    > div:last-of-type {
      border-left: 1px solid $black;
    }
  }

  .bar {
    background: linear-gradient(
      180deg,
      rgba(0,0,0,0) calc(50% - 1px),
      rgba(192,192,192,1) calc(50%),
      rgba(0,0,0,0) calc(50% + 1px)
    );
    height: 0.5rem;
    border-width: 0;
    border-left-width: 2px;
    border-right-width: 2px;
    border-color: $material-primary-blue;
    border-style: solid;
  }
}

.classification-submissions [class^=MuiTableCell] {
  &.low {
    background-color: $classification-risk-low;
  }

  &.moderate {
    background-color: $classification-risk-moderate;
  }

  &.high {
    background-color: $classification-risk-high;
  }
}

.classification-submissions, .classification-submission {
  .action-required {
    background-color: $tomato-red;
    border-radius: 2rem;
    color: $white;
  }
}

.classification-submission {
  .would-you-like {
    max-width: 12rem;
  }

  .publish-button {
    background-color: $tomato-red;
    color: $white;
  }

  table {
    .low {
      background-color: $classification-risk-low;
    }

    .moderate {
      background-color: $classification-risk-moderate;
    }

    .high {
      background-color: $classification-risk-high;
    }
  }

  .events {
    .event {
      .dot {
        border-radius: 0.75rem;
        height: 0.75rem;
        width: 0.75rem;
      }

      .body {
        background-color: $pale-gray;
        width: calc(100% - 2rem);

        .label {
          text-transform: uppercase;
        }

        .datetime {
          color: $warm-gray;
        }
      }

      &.note {
        .dot {
          background-color: $lightblue-two;
        }
      }

      &.reset {
        .dot {
          background-color: $pending;
        }

        .label {
          color: $pending;
        }
      }

      &.published {
        .dot {
          background-color: $medium-green;
        }

        .label {
          color: $medium-green;
        }
      }
    }
  }
}

.MuiMenu-list .MuiButtonBase-root {
  display: block;
}

.user-note {
  background-color: $pale-gray-five;
  border-radius: 4px;
}

.user-notes-organization-select {
  min-width: 200px;
}
