.area-collapsible {
  &__toggle {
    text-decoration: none;
    font-size: 12px;
    color: $warm-gray;
    margin: 0 15px;
  }

  &__content--collapsed {
    height: 1px;
    max-height: 1px;
    opacity: 0;
    overflow: hidden;
  }

  .area-collapsible__header {
    margin: 10px 0;

    &:hover {
      cursor: pointer;
    }

    > div {
      max-width: 80%;
    }
  }

  &--solid-color {
    background-color: $pale-gray-two;
    padding: 16px;

    > h3 {
      margin: 0 !important;
      padding: 0 !important;
    }

    .area-collapsible__content {
      margin-top: 0;
    }

    &.expanded .area-collapsible__content {
      margin-top: 10px;
    }

    .area-collapsible__toggle {
      color: $greyish-brown !important;
      padding-left: 16px;
    }

    .area-collapsible__header:empty {
      display: none !important;

      + .area-collapsible__content {
        margin-top: 0;
      }
    }
  }

  &--fieldset {
    margin-bottom: 10px;

    .area-collapsible__content {
      position: relative;
      top: -9px;
    }

    .area-collapsible__header {
      color: $td-blue;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      position:relative;

      &::before {
        content: "";
        display: block;
        border-top: solid 1px $silver;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        z-index: 0;
      }

      span {
        background: #fff;
        padding: 0 5px;
        margin-left: 15px;
        position: relative;
        z-index: 1;
      }
    }

    &.expanded {
      .area-collapsible__content {
        border: 1px solid $silver;
        padding: 15px 20px;
      }
      .area-collapsible__header {
        &:before {
          border: none;
        }
      }
    }
  }

  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    color: $black;
  }

  .form-group {
    margin-bottom: 5px;

    + .form-group {
      margin-top: 20px;
    }
  }
}

.card .area-collapsible__header .area-collapsible__toggle {
  margin-left: 0;
  margin-right: 5px;
}
