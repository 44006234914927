
.on-demand-payouts {
  th {
    font-size: 0.75rem;
    font-weight: 600;
  }

  tbody > tr[resource="on_demand_payouts"] {
    &:nth-of-type(odd) {
      transition-duration: 300ms;
      background-color: rgb(228, 231, 249);
      &:hover {
        background-color: rgb(239, 240, 249);
      }
    }
  }

  &__columns {
    gap: 32px;
  }

  &__primary-column {
    width: 40%;
  }

  &__secondary-column {
    width: 20%;
  }

  &__column-title {
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__card {
    font-weight: 300;
    font-size: 0.75rem;

    div {
      padding-bottom: 8px;
    }
  }

  &__card-details {
    font-weight: 500;
  }

  &__card-subtitle {
    font-weight: 700;
    font-size: 0.8rem;
  }

  &__transactions {
    font-weight: 300;
    font-size: 0.75rem;

    >div >div >div {
      padding-bottom: 8px;

      >span:nth-child(1) {
        &:first-letter {
          text-transform: uppercase;
        }
      }

      :not(:nth-child(1)) {
        font-weight: 500;
      }
    }
  }
}

