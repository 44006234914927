.allocation-bulk-actions-container {
  [class^="MuiToolbar-root"] {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
    button:not(.allocation-button),
    [class^="MuiTypography-root"] {
      display: none !important;
    }
  }
}

@media (max-width: 1350px) {
  .allocation-bulk-actions-container {
    [class*="MuiToolbar-root"] .actions-wrapper {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;

      button {
        margin-top: 15px;
      }
    }
  }
}
