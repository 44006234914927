.profile-pic {
  display: inline-block;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
  z-index: 1;
  
  &--squared {
    border-radius: 24px !important;
  }
}
