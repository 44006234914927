.organization_view {
  margin: 0;

  > div {
    padding: 0;
    margin: 0;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
    background-color: transparent;
  }

  .MuiCardContent-root {
    margin: 0;
  }

  .organization_view_tabs {
    background-color: $white;
    + div {
      padding: 0;
    }
  }

  .organization_view_tab {
    background-color: $transparent;
    &__card {
      &__header {
        margin-top: 24px;
        margin-bottom: 0px;

        &--dropdown-btn {
          width: 42px;
          height: 48px;
        }
      }

      &__content {
        &__infotab {
          background-color: $transparent;
          margin: 0px;
          padding: 0px;
        }
        background-color: $white;
        border-radius: 4px;
        padding: 0px;
      }

      &__actions {
        border-top: 1px solid #E0E0E0;
        background-color: $white;
        padding: 16px;
      }
    }
  }

  .organization_view_field {
    display: flex;
    flex-direction: column;

    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.54);
    }

    &__value {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  
  .organization_view_image_field {
    display: flex;
    flex-direction: column;

    &__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.54);
    }

    &__value {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.87);

      .img {
        max-height: 100px;
        max-width: 100px;
      }
    }
  }

  .organization_view_switch_field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
    border-bottom: 1px solid #E0E0E0;
    padding: 13px 16px;

    &__label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 35px;
      margin: 0;

      .sublabel {
        color: $warm-gray;
        font-size: 13px;
      }
    }

    &__switch {
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}


.organization_view_tab__card__header {
  &--dropdown-menu {
    .MuiMenu-paper {
      margin-left: -34px;
      margin-top: 50px;
    }

    .MuiMenuItem-root {
      padding: 10px 8px 10px 15px;
      min-width: 250px;
    }
  }

  &--dropdown-menu-item-danger {
    color: #DC3545 !important;
  }
}